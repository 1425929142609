<template>
	<div class="mt-1">
		<select
			v-model.trim="formData.category"
			class="w100P font-size-12 cur_p lh32 pl-3 backColorWhite borderRadi5Px borderContColor1"
		>
			<option value="ALL">전체</option>
			<option value="GROUP">그룹</option>
			<option value="INDV">개인</option>
		</select>
		<div class="new-btnA1">
			<textarea
				v-model.trim="formData.memoContents"
				class="mt-1 backColorWhite borderContColor1 outLineNone"
				:class="{ w460: loadFromPopup }"
				placeholder="메모 입력"
				maxlength="255"
				type="text"
				style="
					resize: none;
					font-size: 12px;
					height: 66px;
					border-radius: 5px 0 0 5px;
					width: 250px;
					padding: 4px 6px;
					outline: none;
				"
			/>
			<button style="outline: none" @click="addList">확인</button>
		</div>
	</div>
</template>

<script>
import { nullValidation2 } from '@/common/common';

export default {
	props: {
		loadFromPopup: { type: Boolean, default: false },
	},
	data: () => ({
		formData: {
			applId: 0,
			category: 'ALL',
			memoContents: '',
		},
	}),
	computed: {
		tabValue() {
			return this.$store.state.memoModule.tabValue;
		},
		applId() {
			return this.$store.state.memoModule.applId;
		},
	},
	methods: {
		async addList() {
			if (!this.formData.memoContents) {
				alert('메모 내용을 입력해 주세요.');
				return;
			}
			let data = this.formData;
			if (!confirm('등록하시겠습니까?')) {
				return;
			}
			const result = await this.$store.dispatch('memoModule/addList', data);
			if (result) {
				alert('등록되었습니다.');
				this.formData.category = 'ALL';
				this.formData.memoContents = '';
				let data = {
					applId: this.applId,
					category: this.tabValue,
				};
				await this.$store.dispatch('memoModule/getMemoList', data);
				await this.getList();
			}
		},
		async getList() {
			const data = this.$store.state.SellStatusModule.filterData;
			await this.$store.dispatch('SellStatusModule/getSellCurrentPage', data);
		},
	},
	created() {
		this.formData.applId = this.applId;
	},
};
</script>

<style scoped></style>
